.TabPane__menu__item {
    color: white;
    border-bottom: 1px solid white;
    cursor: pointer;
    padding: .5em;
    color: var(--primaryColor);
    transition: .2s ease-in-out;
    box-sizing: border-box;
    user-select: none;
}

.TabPane__menu__item:hover {
    border: 1px solid white;
    border-bottom: 1px solid white;
    border-radius: 4px 4px 0 0;
}


.TabPane__menu__item.active {
    color: white;
    border: 1px solid white;
    border-bottom: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}