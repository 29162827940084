.selectPlayerContainer {
    cursor: pointer;
    background-color: transparent;
    user-select: none;
    color: #18171750;
    border: none;
    transition: .2s ease-in-out;
    border: 1px dashed #33333350;
    height: auto;
    overflow-y: auto;
    box-sizing: border-box;
    gap: 10px;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-color: var(--inputBorderColor) !important;
}

.selectPlayerContainerActionsBtn {
    cursor: pointer;
    background-color: var(--inputBorderColor);
    user-select: none;
    color: #ffffff90;
    border: none;
    transition: .2s ease-in-out;
    height: auto;
    overflow-y: auto;
    box-sizing: border-box;
    gap: 10px;
    padding: 5px;
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

select[name="game_id"] option{
    color:  var(--inputBorderColor) !important;
    background-color: #273134 !important;
}

.selectPlayerContainer:hover {
    border: 1px dashed var(--primaryHoverColor);
}

.players-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
}

/* input[type="checkbox"] {
    width: 15px !important;
    height: 15px !important;
    accent-color: var(--primaryHoverColor) !important;
} */

.voteResult {
    display: flex;
    align-items: center;
    background-color: red;
    color: #fff;
    border-radius: 4px;
    padding: 4px;
    font-weight: bold;
}



@media screen and (max-width: 1400px) {
    .players-list {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
    }
}

@media screen and (max-width: 1000px) {
    .players-list {
        grid-template-columns: repeat(2, minmax(336px, 1fr)) !important;
        margin-top: 0px !important;
    }
}

@media screen and (max-width: 800px) {
    .players-list {
        height: 50vh;
        overflow-y: scroll;
    }

    .players-list {
        grid-template-columns: repeat(1, minmax(336px, 1fr)) !important;
    }
}


.chip {
    border: 1px solid var(--inputBorderColor);
    color: var(--inputBorderColor);
    width: max-content;
    height: max-content;
    border-radius: 25px;
    padding: 4px;
    transition: .2s ease;
}

.chip:hover {
    scale: 1.02;
}

.chip.active {
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor);
    background-color: transparent;
}