.LoginForm {
    color: #fafafa !important;
    background-color: #28323690;
}

@media (min-width:768px) {
    .LoginForm {
        width: 640px;
    }
}

.form-error {
    color: #dc3545;
}

.LoginForm input:not([type='radio'], [type='submit']) {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--inputBorderColor);
    border-radius: 0px;
    /* width: 100%; */
    color: white !important;
    outline: 0 !important;
}

.LoginForm input.primary-border:not([type='radio'], [type='submit']) {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--inputBorderColor);
}

.PhoneInputCountryIcon--border {
    background-color: transparent !important;
    box-shadow: none !important;
}

.LoginForm input[type='submit'] {
    background-color: var(--primaryColor);
    border-radius: var(--buttonRaduis);
    border: 1px solid var(--primaryColor);
    font-weight: bold;
    height: 35px;
}

#togglePasswordVisibility {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--inputBorderColor);
    border-radius: 0;
    color: var(--inputBorderColor);
    cursor: pointer;
    transition: .2s ease-in;
}

.input-group input:focus+#togglePasswordVisibility {
    border-color: white !important;
}

/* ANCHOR */
a {
    color: white;
    text-decoration: none;
}

a:hover {
    color: var(--primaryColor);
}