
.iSAVrt {
    background-color: #00000020 !important;
    border-bottom-width: none;
    color: #fff;
}


.kVrXuC {
    color: #fff !important;
    background-color: #00000020 !important;
}

.dwPlXY {
    color: rgba(255,255,255, 0.8) !important;
    background-color: #2027251c !important;
}

.text-area-td{
    color: rgb(255, 255, 255);
    padding: 5px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.24) !important;
}