.ShopItem {
    background-color: white;
    border-radius: 4px;
    height: 380px;
    scale: .9;
}

.ShopItem__image img {
    height: 260px;
}

.ShopItem__meta h3 {
    color: #3f4139;
}

.ShopItem__meta__AddToCartBtn {
    background-color: var(--primaryColor);
    border-radius: 6px;
    cursor: pointer;
    color: #1B262A !important;
    transition: .3s ease-in-out;
    min-width: 40px;
    max-height: 50px;
}

.ShopItem__meta__AddToCartBtn>span {
    width: 0;
    transition: .3s ease-in-out;
    overflow: hidden;
}

.ShopItem__meta__AddToCartBtn:hover span {
    width: max-content;
}

.ShopItem__meta__AddToCartBtn i {
    color: #1B262A;
    transition: .2s ease-in-out;
}

.ShopItem__meta__AddToCartBtn:hover i {
    color: white !important;
}

.ShopItem__meta__AddToCartBtn:hover {
    background-color: #1B262A;
    color: white !important;
}

.ShopItem__edit {
    cursor: pointer;
    font-size: 1.4em;
    transition: .2s ease-in-out;
}

.ShopItem__edit:hover {
    scale: 1.1;
}