.CardCarouselControllerActions button {
    background-color: transparent !important;
    border-radius: 50% !important;
    border: 2px solid white !important;
    color: white;
}

.CardCarouselContainer {
    position: relative;
    max-width: 100%;
}


.CardCarousel {
    position: relative;
}

.card-item {
    opacity: .6 !important;
    scale: .7;
    min-width: calc(100% / 5);
    pointer-events: none;
    user-select: none;
    margin-left: -.8em !important;
    /* animation: zoomIn2 3s ease infinite alternate-reverse; */
}

.card-item-middle {
    scale: 1.0;
    opacity: 1 !important;
    pointer-events: initial;
    user-select: initial;
    animation: none !important;
}

@media (max-width: 992px) {
    .activeCardContent {
        scale: .9;
    }

    .CardCarousel {
        margin-top: 110px !important;
    }
}

@media (max-width: 576px) {
    .card-item-middle {
        scale: 1.5;
        z-index: 2;
    }

    .CardCarousel {
        margin-top: 200px !important;
    }

    .activeCardContent {
        scale: .7;
    }
    .tooltiptext::after{
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.3);
    }
}

@keyframes zoomIn2 {
    0% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

.deeplink {
    display: inline-block;
    padding: 1px;
    color: #757575;
    background-color: var(--primaryColor);
    border: none;
    border-radius: 4px;
    padding: .2em;
    transition: .2s ease-in-out;
    animation: deeplinkAnimation .8s ease-in-out alternate-reverse infinite;
}

.deeplink:hover {
    scale: 1.01;
    color: black;
}

@keyframes deeplinkAnimation {
    0% {
        scale: .95;
    }

    100% {
        scale: 1.01;
        color: black;
    }
}

.card-item {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid red; */
}

.activeCardContent {
    position: absolute;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -0px;
}

.tooltiptext {
    position: relative;
    visibility: visible;
    background-color: #2a3438aa;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 4px;
    position: absolute;
    z-index: 5;
    width: 300px;
    height: 110px;
    box-sizing: border-box;
    overflow-y: auto;
    top: -120px;
    overflow: hidden;
    overflow-y: auto;
    font-size: .9em;
    font-weight: normal;
    font-family: sans-serif;
    animation: revealTooltip .9s ease-in-out;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

/* width */
.tooltiptext::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
.tooltiptext::-webkit-scrollbar-track {
    background: #75757550;
}

/* Handle */
.tooltiptext::-webkit-scrollbar-thumb {
    background: transparent;
}

/* Handle on hover */
.tooltiptext::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.activeCardContent::after {
    position: absolute;
    content: ' ';
    left: calc(50% - 7.5px);
    top: -10px;
    height: 0;
    width: 0;
    z-index: 5;
    background-color: transparent;
    border: 9px solid transparent;
    border-top: 9px solid #2a3438aa;
    /* box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.3); */
    animation: revealTooltipArrow 1s ease-in-out;
}

@keyframes revealTooltipArrow {
    0% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes revealTooltip {
    0% {
        scale: 0;
    }

    100% {
        scale: 1;
    }
}

.img-external{
    border: 1px dashed red;
}

.name-external{
    border-bottom: 1px dashed red;
}