.mobileForm {
    height: 0px;
    overflow: hidden;
    transition: .3s ease-in-out;
}

.mobileForm.open {
    height: 250px;
    overflow: hidden;
}

.form-check-input {
    cursor: pointer;
    transition: .2s ease-in-out;
}

.form-check-input:checked {
    background-color: var(--primaryColor);
    border: 1px solid var(--borderColor);
    outline: none !important;
    appearance: none !important;
    box-shadow: none !important;
}

.paymentForm input:focus,
.paymentForm select:focus,
.paymentForm input:focus {
    border: 1px solid var(--primaryColor) !important;
    caret-color: black !important;
    outline: none !important;
    box-shadow: none !important;
}