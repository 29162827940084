.tab_pane li{
    position: relative;
    list-style: none;
    overflow: hidden;
    cursor: pointer;
}

.tab_pane li.active::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    background: #198754;
    left: 0;
}

.player_details{
    position: absolute;
    top: 0;
    left: 0;
}

.small, small {
    font-size: .675em;
}

.modal{
    display: block;
    background: #0000007a;
    backdrop-filter: blur(5px);
}

.modal-content {
    color: #fff;
    background-color: #2125296b;
    border: none;
    border-radius: 10px;
}

.modal-header {
    border-bottom: none;
}

.modal-footer {
    border-top: none;
}

/* .form-control {
    color: #fff !important;
    background: #616667;
    border: none;
} */

.btn-close {
    color: #fff !important;
}