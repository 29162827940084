.ShopCart {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 0;
    height: 100vh;
    overflow: hidden;
    background-color: var(--sideBarBG);
    color: var(--sideBarColor);
    transition: .3s ease-in-out;
}

.ShopCart.open {
    width: 300px;
}

.ShopCart__close i {
    cursor: pointer;
    transition: .2s ease-in-out;
    font-size: 1.3em;
    color: #a1a1a1;
}

.ShopCart__close i:hover {
    scale: 1.1;
    color: #ffffff;
}

.CartItemContainer{
    height: 85vh;
    overflow: hidden auto;
}

.payButton{
    background-color: var(--primaryColor);
    background: linear-gradient(white);
    transition: .3s ease-in-out;
}

.payButton:hover{
    background-color: black !important;
    color: var(--primaryColor) !important;
}