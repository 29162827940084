.NoVote{
    user-select: none;
    pointer-events: none;
    height: 50vh !important;
}
.NoVote {
    color: #ffffff20;
}

.searchBar{
    color: white !important;
}