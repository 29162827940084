.avatarIMG,
.itemIMG {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.itemIMG {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.itemIMG {
    border-radius: 6px;
}

.avatarIMG:hover,
.itemIMG:hover {
    filter: contrast(50%);
}

.avatarIMG~i,
.itemIMG~i {
    cursor: pointer;
    position: absolute;
    color: #FAFAFA !important;
    font-size: 1.3em;
    margin-left: 90px;
    margin-bottom: 20px;
}

div:has(>.avatarIMG),
div:has(>.itemIMG) {
    position: relative;
}

.modal-backdrop {
    display: none;
}

.imageContainer {
    cursor: pointer;
}

.ProfilForm input:disabled:not([type='submit']) {
    border: none !important;
}