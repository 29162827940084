.CardData__label {
    font-size: 1.3em;
}

.CardData__image {
    height: max-content !important;
}

.tinnyCardData {
    background-color: #8F8F8F50;
    color: white !important;
}
.CardDataContainer{
    width: 100%;
    /* border: 1px solid red; */
}
@media (max-width: 576px) {
    .CardDataContainer{
        justify-content: space-between !important;
    }
}
.childrenContent {
    width: calc(100vw/3 - 5.5em);
    overflow: auto;
    padding: 3px;
}

/* width */
.childrenContent::-webkit-scrollbar {
    width: 3px;
    height: 5px;
    border-radius: 4px;
}

/* Track */
.childrenContent::-webkit-scrollbar-track {
    background: #8F8F8F90;
    border-radius: 4px;
}

/* Handle */
.childrenContent::-webkit-scrollbar-thumb {
    background: #4e4d4d90;
    border-radius: 4px;
    transition: .2s ease-in;
}

.childrenContent:hover::-webkit-scrollbar-thumb {
    background: #888;
}

@media (max-width: 576px) {
    .childrenContent {
        width: calc(100vw - 1.5em);
        overflow: auto;
    }
}