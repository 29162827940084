
.react-daterange-picker__wrapper {
    border: none !important;
}

.react-daterange-picker__clear-button.react-daterange-picker__button{
    display: none !important;
}
.react-calendar, .react-calendar__viewContainer{
    background-color: #ffffff !important;
    border-radius: 4px !important;
}
.react-daterange-picker__calendar.react-daterange-picker__calendar--open{
    background-color: transparent;
    border: none;
}
.reloadContainer{
    background-color: var(--white);
    border-radius: 4px;
    padding: 15px;
}

.reloadBtn{
    background-color: #f5f5f5;
    color: var(--black);
    border-color: rgb(220, 220, 220) !important;
}
.reloadBtn:hover{
    background-color: #f0f0f0 !important;
    scale: .98;
    color: var(--black);
}