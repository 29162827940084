body:has(.mainVideo) {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.mainVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 1;
}

.AppContainer:has(.mainVideo)::after {
    z-index: 1;
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(circle, rgba(255, 255, 255, .1) 0%, rgba(0, 0, 0, 0.8183648459383753) 100%);
}

.AppContainer:has(.mainVideo) .NavbarContainer {
    position: absolute;
    z-index: 20;
    top: 0;
    right: 0;
    padding: .5em;
    /* border: 1px solid red; */
}

.AppContainer:has(.mainVideo) .NavbarContainer .Navbar {
    justify-content: flex-end !important;
    gap: 2.3em;
    padding: 0 .9em;
}

.AppContainer:has(.mainVideo) .NavbarContainer .Navbar .Navbar__links img {
    display: none;
}

.homeContent {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* border: 1px solid red; */
    background-color: #00000050;
    z-index: 3;
    overflow: hidden;
    /* overflow-y: scroll; */
}

.homeContent::-webkit-scrollbar{
    display: none;
}

.homeContent__home>div {
    height: 100vh;
}

.homeContent .homeContent__item {
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    /* border: 1px solid green; */
    /* font-size: 75px; */
    /* color: var(--primaryColor); */
}

.homeContent__item img.brand {
    width: 50%;
}

.bonBaDjob {
    position: absolute;
    bottom: 1em;
    right: 2em;
    width: 100%;
    user-select: none;
}

.bonBaDjob .verticalLine {
    width: 2px;
    height: 18px;
    rotate: 30deg;
    background-color: #f5f5f5;
}

.bonBaDjob .bonBaDjob__content {
    position: absolute;
    bottom: 0;
    right: 0;
    font-weight: 400;
    font-size: 1.2em;
    color: white;
    word-spacing: .4em;
}

.cardItem{
    width: 400px !important;
}

.cardItem img{
    width: 400px !important;
}

.homeContent__card img{
    width: 200px !important;
}

.homeContent__article{
    overflow: hidden;
    /* overflow-y: scroll; */
}

.floattingController{
    position: absolute;
    top: calc(50%);
    right: 15px;
    z-index: 15;
    /* border: 2px solid red; */
}
@media (max-width: 576px) {
    .floattingController{
        display: none;
    }
}

.floattingController__button{
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.floattingController__button:hover{
    color: var(--primaryColor);
    scale: 1.05;
    font-weight: bold;
    cursor: pointer;
    transition: .2s ease-in-out;
}

/*   */

@media (max-width: 926px) {
    #itemSection{
        max-width: 100vw;
        overflow-y: auto;
    }
    .ShopItemContainer{
        min-width: 43% !important;
    }
}
@media (max-width: 576px) {
    .ShopItemContainer{
        min-width: 58% !important;
    }
}